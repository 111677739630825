"use client"

import { Icon } from "@/components/ui/icon"
import { cn, onKeyPress } from "@/lib/utils"
import { useRouter } from "next/navigation"
import { useCallback, useState } from "react"

type Props<T> = {
  className?: string
  prependPath: string
  placeholder?: string
  items: T[]
}
const SearchBar = <T extends { uid: string; name: string }>({
  className,
  prependPath,
  items,
  placeholder = "Search by name...",
}: Props<T>) => {
  const router = useRouter()

  const [query, setQuery] = useState("")
  const [filteredSuggestions, setFilteredSuggestions] = useState<T[]>([])

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = (e.target as HTMLInputElement).value
      setQuery(value)

      if (value) {
        const filtered = items.filter((e) =>
          e.name.toLowerCase().includes(value.toLowerCase())
        )
        setFilteredSuggestions(filtered)
      } else {
        setFilteredSuggestions([])
      }
    },
    [items]
  )

  const handleSuggestionClick = useCallback(
    (suggestion: T) => {
      setQuery(suggestion.name)
      setFilteredSuggestions([])
      router.push(`/${prependPath}/${suggestion.uid}`)
    },
    [prependPath, router]
  )

  return (
    <div
      className={cn(
        "flex w-full items-center justify-between gap-4 py-2",
        className
      )}
    >
      <div className="relative w-full rounded-lg border">
        <input
          type="text"
          className="w-full rounded-md px-4 py-2 text-muted-foreground shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder={placeholder}
          onChange={handleInputChange}
          aria-autocomplete="list"
          aria-controls="suggestions-list"
          autoComplete="off"
        />
        <Icon
          name="heroicons--magnifying-glass-solid"
          className="absolute inset-y-0 right-0 flex h-8 w-8 items-center pr-3 text-[#676767]"
        />
        {filteredSuggestions.length > 0 && (
          <ul
            role="listbox"
            aria-label="Suggestions"
            className="absolute z-10 mt-1 max-h-60 w-full overflow-y-auto rounded-md border bg-background text-muted-foreground"
          >
            {filteredSuggestions.map((suggestion, index) => (
              <li
                tabIndex={0}
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                onKeyUp={(e) =>
                  onKeyPress(e, () => handleSuggestionClick(suggestion))
                }
                className="cursor-pointer p-2 text-start hover:bg-gray-200"
                role="option"
                aria-selected={query === suggestion.name}
              >
                {suggestion.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default SearchBar
